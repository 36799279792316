#table{
      margin-left: 20%;
      margin-right: 20%;
}
th{
   font-size: larger;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   color: rgb(41, 214, 41);
   border: 1px solid green;
}
td{
   border: 1px solid green;
   font-style: italic;
}
h4{
   color: rgb(5, 203, 5);
   font-size: x-large;
   font-family: 'Times New Roman', Times, serif;
   font-weight: 500;
   font-style: inherit;
}
#total{
   margin-left: 20%;
      margin-right: 20%;
}
#total h5{
   color: rgb(0, 0, 0);
   font-weight: 500;
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
#total span{
color: rgb(227, 227, 63);
font-size: x-large;
font-weight: bold;

font-style: italic;
padding-left: 5%;
}