#faqs{
   max-width: 60vw;
   margin-left: 20%;
   margin-right: 20%;
}
#faqs h4{
   color: rgb(241, 180, 180);
}
p{
   color: beige;
   font-style: italic;
}
h5{
   color: rgb(82, 82, 163);
}
h6{
   color: rgb(95, 95, 160);
}
#background{
   background-color: rgb(28, 27, 27);
}