#logo img{
   max-height: 60px;
   max-width: 100px;

}


#logo{
   display: flex;
   flex-direction: column-reverse;
}
#logo h5{
   color: rgb(0, 224, 0);
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   font-weight: bolder;
}

nav{
   margin: none;
   width:100vw;
   background-color: rgb(51, 47, 47);
}
