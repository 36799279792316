#book-card-cover{
   height: 250px;
   width: 200px;
  margin: 5px 10px;
   
}
#description{
 display: flex;
 flex-direction: row;
 gap: 20px;
 align-items: center;
 margin-bottom: 4%;
 
}
#description p{
   margin: auto;
   font-style: italic;
   color: rgb(1, 204, 5);
}

#main-container{
   max-width: fit-content;
   
}
#searchbar{
   display: flex;
   height: 50px;
   justify-content: space-between;
   align-items: center;
   
   color: rgb(147, 147, 22);
   margin-left: 20%;
   margin-right: 20%;
   border-radius: 5%;
   padding: 10px 30px;
}
#searchbar button{
   height: 40px;
   align-content: center;
}
