#footer-logo{
   max-height: 60px;
}
#footer{
   margin: none;
   width:100vw;
   background-color: rgb(51, 47, 47);
  
}
#footer li{
   color: bisque;
}