#about{
   color: rgb(90, 73, 73);
   max-width: 45vw;
   height: 72vh; 
}
#about p{
   font-family: Georgia, 'Times New Roman', Times, serif;
   color: beige;
   font-size: larger;
}
#title{
   color: rgb(0, 219, 0);
}