#main{
   min-height: 70vh;
   
   color: rgb(252, 255, 255);
}

#single-book{
  
   display: flex;
   justify-content: space-evenly;
   gap: 5%;
   
   padding-left: 5%;
   padding-right: 5%;
   padding-top: 3%;
}
#image img{
   height: 65vh;
   min-width: 25vw;
}

#main-des{
   max-width: 50vw;
   min-height: 60vh;
   color: bisque;
}
#main-des p{
   font-family: Arial, Helvetica, sans-serif;
   color: rgb(238, 196, 145);
}
#descriptions{
   display: flex;
   justify-content: space-around;
}
span{
   font-style: italic;
   font-family: fantasy;
   color:rgb(0, 255, 13);
   margin-left: 5px;
}
#title-edit{
   display: flex;
   justify-content: space-between;
}
#editBtn{
   border: 2px;
   border-radius: 10%;
   background-color: rgb(23, 222, 23);
}
#deleteBtn{
   border: 2px;
   border-radius: 10%;
   background-color: red;
}