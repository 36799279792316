#contacts{
   color: bisque;
   margin-left: 15%;
   margin-right: 15%;
   border: none;
}
li{
   display: flex;
   align-items: flex-start;
   gap: 10px;
}
i{
   color: rgb(9, 102, 231);
}
#contacts p{
   color: antiquewhite;

}
#contacts h2{
   font-weight: bold;
   color: rgb(51, 99, 233);
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}