#add-book{
  
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   /* align-items: center; */
}
#page-title{
   width: fit-content;
}
#page-title h1{
margin-top: 10%;
color: rgb(51, 255, 0);
font-size: 100px;
font-style: oblique;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
#page-title h4{
   margin-top: 10%;
color: rgb(51, 255, 0);
font-size: 70px;
font-style: oblique;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
form{
   margin-top: 3%;
   margin-right: 3%;
   margin-bottom: 3%;
   color: beige;
   border: 1px solid rgb(16, 14, 14);
}
#addBtn{
   background-color: rgb(51, 255, 0) ;
}
